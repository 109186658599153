import { useEffect, useState } from "react";
import { Modal, useToast, Label, Tag, ProgressLoader } from "@withjuly/solisv2";
import { FormProvider } from "react-hook-form";
import { useZodForm } from "~/utils/hooks/zod-form";
import {
	AgencyBillingError,
	InviteTalentSchema,
	PhylloError,
} from "@withjuly/fabric";
import { trpc } from "../Utility/trpc";
import { useAuth } from "~/utils/context/auth";
import { ZodInput } from "../Input/ZodInput";
import { ToggleSwitchInput } from "../Input/ToggleSwitchInput";
import { Sparkle, Warning } from "@withjuly/julycons/fill";
import { LinkSimple, Plugs } from "@withjuly/julycons/bold";
import posthog from "posthog-js";

interface CheckState {
	invite: boolean;
	auth: boolean;
}

export const InviteTalentModal = ({
	isOpen,
	alreadyChecked,
	setIsOpen,
	setTimer,
	continueChecked,
}: {
	isOpen: boolean;
	alreadyChecked: CheckState;
	setIsOpen: (isOpen: boolean) => void;
	setTimer: () => void;
	continueChecked: (checkState: CheckState) => void;
}) => {
	const { user } = useAuth();
	const invite = trpc.agency.inviteTalent.useMutation();
	const noAuthInvite = trpc.agency.inviteNoAuthTalent.useMutation();
	const { toast } = useToast();
	const [checked, setChecked] = useState(alreadyChecked.invite);
	const [isLoading, setisLoading] = useState(false);
	const isNonExclusiveEnabled =
		posthog?.isFeatureEnabled("non-exclusive-talent") ?? false;
	const [isConnecting, setIsConnecting] = useState(false);
	const isNoAuthEnabled = posthog?.isFeatureEnabled("no-auth-talent") ?? false;

	const utils = trpc.useContext();
	const form = useZodForm({
		schema: InviteTalentSchema,
		values: {
			firstName: "",
			lastName: "",
			displayName: null,
			email: "",
			username: "",
			managerEmail: user?.email ?? "",
			exclusive: true,
			isAuth: alreadyChecked.auth,
			sendOnboardingSurvey: false,
			platformUsernames: {
				instagramUsername: "",
				youtubeUsername: "",
				tiktokUsername: "",
			},
		},
		submit: async (values, { onFormError }) => {
			if (values.isAuth) {
				invite.mutateAsync(
					{ ...values, isAuth: true },
					{
						onSuccess: () => {
							setIsOpen(false);
							setisLoading(false);
							utils.agency.get.invalidate();
							toast({
								title: "Invite link sent",
								description: `July sent an invite link to ${values.email}.`,
								variant: "success",
							});
							form.reset();
							if (checked) {
								setTimer();
							}
							if (checked) {
								continueChecked({ invite: checked, auth: fields.isAuth });
							} else {
								continueChecked({ invite: checked, auth: true });
							}
							7;
						},
						onError: (error) => {
							const parsedError =
								AgencyBillingError.AgencyBillingErrorSchema.safeParse(
									error.data,
								);

							if (parsedError.success) {
								const error = parsedError.data;
								toast({
									variant: "error",
									title: error.message,
									description: error.description,
								});
							} else {
								toast({
									variant: "error",
									title: "Error inviting talent",
									description:
										"There was an error inviting your new talent. Please try again later.",
								});
							}

							setisLoading(false);
							onFormError(error);
						},
					},
				);
			} else {
				noAuthInvite.mutateAsync(
					{ ...values, isAuth: false, exclusive: true },
					{
						onSuccess: () => {
							setIsOpen(false);
							setIsConnecting(false);
							utils.agency.get.invalidate();
							toast({
								title: "Talent added",
								description: `${values.firstName} has been added to your roster.`,
								variant: "success",
							});
							form.reset();
							if (checked) {
								setTimer();
							}

							if (checked) {
								continueChecked({ invite: checked, auth: fields.isAuth });
							} else {
								continueChecked({ invite: checked, auth: true });
							}
						},
						onError: (error) => {
							const parsedError =
								AgencyBillingError.AgencyBillingErrorSchema.safeParse(
									error.data,
								);

							const phylloError = PhylloError.PhylloErrorSchema.safeParse(
								error.data,
							);

							if (parsedError.success) {
								const error = parsedError.data;
								toast({
									variant: "error",
									title: error.message,
									description: error.description,
								});
							} else if (phylloError.success) {
								const error = phylloError.data;
								toast({
									variant: "error",
									title: error.message,
									description: error.description,
								});
							} else {
								onFormError(error);
							}

							setIsConnecting(false);
						},
					},
				);
			}
		},
		mode: "onBlur",
	});

	const fields = form.watch();

	useEffect(() => {
		if (alreadyChecked.invite) {
			continueChecked({ invite: false, auth: true });
		}
	});

	return (
		<Modal.Root
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			size={isConnecting ? "md" : "lg"}
		>
			{!isConnecting ? <Modal.Header title="Invite talent" /> : null}
			<Modal.Body>
				{isConnecting ? (
					<div className="my-20 flex h-full flex-col items-center justify-center">
						<div className="bg-sky-alpha-4 mb-4 w-fit rounded-[8px] p-2">
							<Plugs className="text-brand h-6 w-6" />
						</div>
						<div className="mb-8 flex flex-col items-center justify-center gap-[2px]">
							<p className="text-header-lg font-repro">Linking socials</p>
							<p className="text-text-tertiary text-paragraph-sm font-repro">
								Just a few moments...
							</p>
						</div>
						<ProgressLoader />
					</div>
				) : (
					<FormProvider {...form}>
						<form
							className="flex flex-col gap-6"
							onSubmit={(e) => e.preventDefault()}
						>
							{isNoAuthEnabled ? (
								<div className="bg-surface-quaternary flex-col gap-4 rounded-[8px]">
									<div className="border-stroke-secondary flex items-center gap-4 border-b p-4">
										<div className="flex">
											<ToggleSwitchInput name="isAuth" size="md" />
										</div>
										<Label size="md" variant="paragraph" color="primary">
											Require talent to connect socials
										</Label>
										{fields.isAuth ? (
											<Tag text="Recommended" color="green" />
										) : null}
									</div>

									{fields.isAuth ? (
										<div className="flex gap-3 px-6 py-4">
											<Sparkle />
											<p className="font-repro text-paragraph-sm">
												First-party connections allow access to Instagram
												Stories, audience stats, growth tracking and more.
											</p>
										</div>
									) : (
										<div className="bg-yellow-alpha-1 flex justify-between rounded-[8px] px-6 py-4">
											<div className="flex gap-3">
												<Warning color="#EE913B" />
												<p className="font-repro text-paragraph-sm w-[386px]">
													Stats will update monthly. Instagram Stories, Reports,
													and other features may be restricted or unavailable.
												</p>
											</div>
											<p className="text-paragraph-sm text-yellow-8 font-repro no-wrap">
												+$6/mo extra
											</p>
										</div>
									)}
								</div>
							) : null}

							<div className="flex items-center gap-3">
								<ZodInput
									name="firstName"
									label="First Name"
									placeholder="Add first name"
								/>
								<ZodInput
									name="lastName"
									label="Last Name"
									placeholder="Add last name"
								/>
							</div>

							<ZodInput
								name="displayName"
								label="Display name (Optional)"
								placeholder="Add display name"
								description="Add a display name if talent doesn't go by their actual name on social media."
							/>

							<ZodInput
								name="username"
								label="Set Username"
								placeholder="username"
								leadingIcon={() => <p>july.bio/</p>}
							/>

							<ZodInput
								name="email"
								label="Talent Email"
								placeholder="Add email address"
								description={
									fields.isAuth
										? "A link inviting talent to connect socials will be sent to this email."
										: "Notifications from July will be sent to this address"
								}
							/>

							{!fields.isAuth ? (
								<ToggleSwitchInput
									label="Send onboarding survey to talent"
									name="sendOnboardingSurvey"
									tooltip="If enabled, talent will be sent an email inviting them to complete your onboarding survey."
								/>
							) : null}

							<ZodInput
								name="managerEmail"
								label="Manager Email"
								placeholder="Add email address"
								description="Brands inquiring about talent will be directed here."
							/>

							{isNonExclusiveEnabled && fields.isAuth ? (
								<>
									<div className="bg-stroke-tertiary h-px w-full">
										{/*DIVIDER*/}
									</div>
									<ToggleSwitchInput
										name="exclusive"
										label="Exclusive talent"
									/>
								</>
							) : null}

							{!fields.isAuth ? (
								<>
									<div className="bg-stroke-tertiary h-px w-full" />
									<div className="flex flex-col">
										<div className="flex items-center gap-[6px]">
											<LinkSimple className="h-[14px] w-[14px]" />
											<p className="text-button-sm font-repro">Link accounts</p>
										</div>
										<p className="text-paragraph-sm text-text-secondary font-repro">
											Only add profiles you want to display. Stats will be
											collected within 24 hours
										</p>
									</div>
									<ZodInput
										name="platformUsernames.instagramUsername"
										label="Instagram"
										placeholder="@username"
									/>
									<ZodInput
										name="platformUsernames.tiktokUsername"
										label="TikTok"
										placeholder="@username"
									/>
									<ZodInput
										name="platformUsernames.youtubeUsername"
										label="Youtube"
										placeholder="Link to YouTube channel"
									/>
								</>
							) : null}
						</form>
					</FormProvider>
				)}
			</Modal.Body>

			{!isConnecting ? (
				<Modal.Footer
					checkLabel="Continue inviting creators"
					isChecked={checked}
					onChecked={() => {
						setChecked(() => !checked);
					}}
					primaryLabel={
						fields.isAuth
							? isLoading
								? "Sending invite..."
								: "Send invite link"
							: "Add to roster"
					}
					onPrimaryClicked={() => {
						fields.isAuth ? setisLoading(true) : setIsConnecting(true);
						form.onSubmit();
					}}
					buttons="primary-check"
					layout="separated"
					isPrimaryDisabled={!form.formState.isValid || isLoading}
				/>
			) : null}
		</Modal.Root>
	);
};
