import React, { useId } from "react";
import { Input, InputProps } from "@withjuly/solisv2";
import { useFormWrapper, FormInputWrapper } from "@withjuly/solis";

export const ZodInput: React.FC<InputProps> = ({
	name,
	label,
	description,
	onChange,
	onBlur,
	...rest
}) => {
	const id = useId();
	const { register, error, hasError } = useFormWrapper(name ?? "");
	const form = register(name ?? "", {
		onChange,
		onBlur,
	});

	const describedByIds: string[] = [];
	if (error?.message) {
		describedByIds.push(id + "-error");
	}

	return (
		<FormInputWrapper id={id} name={name}>
			<Input
				error={hasError}
				description={description}
				label={label}
				{...form}
				{...rest}
			/>
		</FormInputWrapper>
	);
};
